"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var styles = exports.styles = {
  container: {
    width: '100%',
    padding: 24,
    fontSize: 20,
    lineHeight: 1.6,
    fontWeight: 500,
    letterSpacing: 'unset'
  }
};