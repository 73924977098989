"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var styles = exports.styles = {
  container: {
    lineHeight: 1.5,
    fontSize: 13,
    padding: [12.5, 15],
    backgroundColor: 'rgba(0, 0, 0, .85)',
    color: '#fff',
    fontWeight: '500',
    maxWidth: 375
  },
  popper: {
    zIndex: 20000
  }
};