"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var styles = exports.styles = {
  container: {
    width: 'fit-content',
    backgroundColor: '#fff',
    borderRadius: 5,
    padding: 20,
    fontFamily: ['Avenir Next', 'open sans', 'Roboto', 'Arial']
  }
};