"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = {
  container: {
    padding: [5, 20],
    display: 'flex',
    '& > svg': {
      maxHeight: 22,
      maxWidth: 22
    }
  }
};