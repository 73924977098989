/* eslint-disable */
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import BlogMain from './pages/BlogMain';
import NotFound from './pages/NotFound';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import { StylesProvider } from '@material-ui/core/styles';
import './styles/global.css';
import './styles/animations.css';

ReactDOM.render(
    <StylesProvider injectFirst>
        <Router>
            <Switch>
                <Route exact path="/" component={App} />
                <Route path="/blog" component={BlogMain} />
                <Route component={NotFound} />
            </Switch>
        </Router>
    </StylesProvider>,
    document.getElementById('root')
);
