"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = {
  popperCard: {
    maxWidth: 600,
    border: 'none',
    '& > ul': {
      listStyleType: 'none',
      margin: 0,
      padding: 0
    }
  },
  listItem: {
    borderRadius: 5
  }
};