import React from 'react';
import { Container } from '@material-ui/core/';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import ArrowForwardIosSharpIcon from '@material-ui/icons/ArrowForwardIosSharp';

import Typography from '@material-ui/core/Typography';
import KeyboardBackspaceTwoToneIcon from '@material-ui/icons/KeyboardBackspaceTwoTone';
import { red } from '@material-ui/core/colors';

const useStyles = makeStyles({
    root: {
        minWidth: 275,
        backgroundColor: 'rgba(25, 68, 74, 0.2)',
        color: '#FFFFFF',
        marginBottom: '20px'
    },
    title: {
        fontSize: 14
    },
    pos: {
        marginBottom: 12
    }
});

export default function Blog(props) {
    const classes = useStyles();
    console.log(props.posts);
    const posts = props.posts.map((post) => {
        return (
            <div>
                <Card className={classes.root} key={post.id}>
                    <CardActionArea href={post.link} target="_blank">
                        <CardContent>
                            <Typography variant="h5" component="h2">
                                {post.title}
                            </Typography>
                            <Typography className={classes.pos}>{post.date}</Typography>
                            <Typography variant="body2" component="p">
                                {post.exp}
                            </Typography>
                        </CardContent>

                        <CardActions>
                            <ArrowForwardIosSharpIcon
                                fontSize="large"
                                style={{ cursor: 'pointer', marginLeft: '95%' }}
                            />
                        </CardActions>
                    </CardActionArea>
                </Card>
            </div>
        );
    });

    return (
        <div>
            <KeyboardBackspaceTwoToneIcon
                fontSize="large"
                onClick={() => (window.location.href = '/')}
                style={{ cursor: 'pointer' }}
            />

            <div className="zoom-area">
                <h1>Blogs</h1>
                <hr></hr>
            </div>

            <Container maxWidth="md">{posts}</Container>
        </div>
    );
}
