import React from 'react';
import './NotFound.css';

export default function NotFound() {
    return (
        <div>
            <h1>Seems like you are searching for Rauf</h1>
            <p className="zoom-area">
                <b>But landed in void</b>
            </p>
            <section className="error-container">
                <span>4</span>
                <span>
                    <span className="screen-reader-text">0</span>
                </span>
                <span>4</span>
            </section>
            <div className="link-container">
                <a target="_self" href="/" className="more-link">
                    {' '}
                    Go back to earth
                </a>
            </div>
        </div>
    );
}
