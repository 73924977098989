'use strict';

Object.defineProperty(exports, '__esModule', {
    value: true
});
exports.DEFAULT_THEME = undefined;

var _palettes = require('./utils/palettes');

var DEFAULT_THEME = (exports.DEFAULT_THEME = {
    palette: {
        primary: _palettes.primary,
        secondary: _palettes.secondary,
        tertiary: _palettes.tertiary,
        light: _palettes.light,
        dark: _palettes.dark,
        safe: _palettes.safe,
        danger: _palettes.danger,
        warn: _palettes.warn
    }
});
