"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var styles = exports.styles = {
  container: {
    width: '100%',
    padding: [8, 24],
    overflowY: 'auto'
  }
};