import React, { Component } from 'react';
import axios from 'axios';
import Blog from './Blog';

export default class BlogMain extends Component {
    constructor(props) {
        super(props);

        this.state = {
            posts: [],
            errMsg: ''
        };
    }

    componentDidMount = async () => {
        const url =
            'https://gist.githubusercontent.com/RaufR/436b4629e7c4041f0b9e8afde3455849/raw/210ae81da9c196cef78656bc8f243c551022ef6c/blogPost.json';

        try {
            const response = await axios.get(url);

            this.setState({ posts: response.data.posts });
            console.log(this.state.posts);
        } catch (error) {
            this.setState({ errMsg: 'Please enable internet connection' });
            console.log(error);
        }
    };

    render() {
        return (
            <div>
                {this.state.errMsg ? <p>Please enable your internet for update</p> : <Blog posts={this.state.posts} />}
                <hr></hr>
            </div>
        );
    }
}
