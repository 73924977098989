/* eslint-disable */

'use strict';

Object.defineProperty(exports, '__esModule', {
    value: true
});
var primary = (exports.primary = {
    50: '#e5e9ec',
    100: '#ccd4d9',
    200: '#b3bfc6',
    300: '#99aab3',
    400: '#8095a1',
    500: '#011a28',
    600: '#4d6b7b',
    700: '#345668',
    800: '#1b4155',
    900: '#022c43',
    A100: '#022c43',
    A200: '#01273c',
    A400: '#012335',
    A700: '#011a28',
    contrastDefaultColor: 'light'
});
var pink = {
    50: '#7fd1ff',
    100: '#66c8ff',
    200: '#4cbfff',
    300: '#32b6ff',
    400: '#19adff',
    500: '#00a4ff',
    600: '#0093e5',
    700: '#0083cc',
    800: '#0072b2',
    900: '#006299',
    contrastDefaultColor: 'light'
};
var secondary = (exports.secondary = pink);
var yellow = {
    50: '#FEFCE4',
    100: '#FDF8BB',
    200: '#FCF38E',
    300: '#FAEE60',
    400: '#F9EB3E',
    500: '#F8E71C',
    600: '#F7E419',
    700: '#F6E014',
    800: '#F5DD11',
    900: '#F3D709',
    contrastDefaultColor: 'dark'
};
var tertiary = (exports.tertiary = yellow);
var safe = (exports.safe = {
    50: '#ecf7f0',
    100: '#d0ebda',
    200: '#b1dec1',
    300: '#91d0a8',
    400: '#7ac695',
    500: '#62bc82',
    600: '#5ab67a',
    700: '#50ad6f',
    800: '#46a565',
    900: '#349752',
    A100: '#e1ffea',
    A200: '#aeffc5',
    A400: '#7bffa1',
    A700: '#62ff8f',
    contrastDefaultColor: 'light'
});
var danger = (exports.danger = {
    50: '#fdeaeb',
    100: '#fbcccc',
    200: '#f8aaaa',
    300: '#f58788',
    400: '#f26e6f',
    500: '#f05455',
    600: '#ee4d4e',
    700: '#ec4344',
    800: '#e93a3b',
    900: '#e5292a',
    A100: '#ffffff',
    A200: '#fff0f0',
    A400: '#ffbdbd',
    A700: '#ffa3a4',
    contrastDefaultColor: 'light'
});
var warn = (exports.warn = {
    50: '#fff3e0',
    100: '#ffe0b2',
    200: '#ffcc80',
    300: '#ffb74d',
    400: '#ffa726',
    500: '#ff9800',
    600: '#fb8c00',
    700: '#f57c00',
    800: '#ef6c00',
    900: '#e65100',
    A100: '#ffd180',
    A200: '#ffab40',
    A400: '#ff9100',
    A700: '#ff6d00',
    contrastDefaultColor: 'light'
});
var orange = (exports.orange = {
    50: '#FFF0E9',
    100: '#FFD8C8',
    200: '#FFBFA3',
    300: '#FFA57E',
    400: '#FF9163',
    500: '#FF7E47',
    600: '#FF7640',
    700: '#FF6B37',
    800: '#FF612F',
    900: '#FF4E20',
    contrastDefaultColor: 'light'
});
var light = (exports.light = {
    500: '#fff',
    900: '#fff',
    contrastDefaultColor: 'dark'
});
var dark = (exports.dark = {
    50: '#E6E6E6',
    100: '#C1C1C1',
    200: '#979797',
    300: '#6D6D6D',
    400: '#4E4E4E',
    500: '#2F2F2F',
    600: '#2A2A2A',
    700: '#232323',
    800: '#1D1D1D',
    900: '#121212',
    contrastDefaultColor: 'light'
});
exports['default'] = {
    primary: primary,
    secondary: secondary,
    tertiary: tertiary,
    dark: dark,
    danger: danger,
    safe: safe,
    warn: warn,
    orange: orange,
    light: light
};
