"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = {
  container: {
    listStyleType: 'none',
    padding: 0,
    margin: 0
  }
};